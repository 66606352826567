// Todo: mailchimp integrations

$newsletter: '.footerBox[class*="ewsletter"]';

#{$newsletter} {

  // Solid colour
  @if $newsletter-background-gradient == none {
    background-color: $newsletter-background-colour;
  }

  // Gradient
  @else {
    background: $newsletter-background-gradient;
  }

  color: $newsletter-colour;

  > h1,
  > h2,
  > span {
    display: block;
    width: 100%;
  }

  > h1,
  > h2 {
    font-family: $newsletter-heading-font-family;
    font-size: $newsletter-heading-font-size;
    text-align: $newsletter-heading-text-align;
    text-transform: $newsletter-heading-text-transform;
    margin-bottom: $newsletter-heading-margin-bottom;
    color: $newsletter-heading-colour;
  }

  .featureForm > div:first-child {
    display: flex;

    .formQuestion {
      display: flex;
      flex-direction: column;
    }
  }

  label {
    text-align: left; // Overwrite $footer-text-align here, we always want them left
  }

  .alert.alert--required {
    @if $newsletter-required-label-enabled {
      color: $newsletter-colour;
    } @else {
      display: none;
    }
  }

  input {
    border: 0; // Remove border here
    background-color: $newsletter-input-background-colour;
  }

  .validateError,
  .validateRequired {
    color: $newsletter-colour;
    font-size: $font-size-small;
  }

  button {
    margin-right: 0;
    height: $newsletter-button-height;
    padding: 0 1em;

    // Solid
    @if $newsletter-button-background-colour != transparent {
      background-color: $newsletter-button-background-colour;
      color: $newsletter-button-colour;
      border-color: $newsletter-button-background-colour;

      &:hover {
        @include button-hover-state($newsletter-button-background-colour);
      }
    }
    // Ghost button
    @else {
      @include ghost-button(
        $border-colour: $newsletter-button-border-colour,
        $text-colour: $newsletter-button-colour
      );
    }
  }

  @if $newsletter-use-placeholders == true {
    label {
      display: none;
    }

    input::placeholder {
      font-size: 1rem;
    }
  } @else {
    input::placeholder {
      font-size: 0;
    }
  }
}

// Logged in newsletter - centralise the text
body.userLoggedIn #{$newsletter} .featureForm > div {
  display: flex;
  flex-direction: column;

  @if $newsletter-heading-text-align == center {
    align-items: center;
  } @else if $newsletter-heading-text-align == left {
    align-items: flex-start;
  }

  button {
    margin: 1rem 0 0;
  }
}

// Everything on one row
@mixin newsletter-hero {
  .pageFooter {
    padding-top: 0;
  }

  #{$newsletter} {
    // grid-column: 1 / -1; // Not supported in IE
    grid-column: 1 / 17; // Effectively same as previous line, but with IE support
    grid-row: 1 / 2;
    width: 100%;
    margin: 0 auto;
    padding: $newsletter-hero-padding-y 0;

    > h1,
    > h2,
    > span {
      max-width: $newsletter-hero-contents-max-width;
      padding: 0 $site-bleed;
      margin-left: auto;
      margin-right: auto;
    }

    .featureForm > div:first-child {
      align-items: flex-end;

      .formQuestion {
        flex: 1;
        margin-right: $spacer * 1.5;
        margin-bottom: $spacer;
        padding-bottom: 0;
      }
    }

    button {
      margin-bottom: $spacer;
    }

    @include max-width(lg) {
      .featureForm > div:first-child {
        flex-wrap: wrap;

        .formQuestion {
          flex: auto;
        }

        .formQuestion.firstName,
        .formQuestion.lastName {
          width: calc((100% - #{$spacer * 2}) / 2);
        }

        .formQuestion.lastName {
          margin-right: 0;
        }
      }
    }

    @include max-width(sm) {
      .featureForm > div:first-child {
        .formQuestion {
          width: 100%;
          margin-right: 0;

          &.firstName,
          &.lastName {
            width: 100%;
          }
        }
      }

      button {
        margin: 0 auto;
      }
    }
  }
}

// Everything in one column
@mixin newsletter-column {
  #{$newsletter} {
    .featureForm > div:first-child {
      flex-direction: column;
    }
  }
}

// First name and last name on top row
// Email and button on second row
@mixin newsletter-split {
  #{$newsletter} {
    .featureForm > div:first-child {
      flex-wrap: wrap;
      align-items: flex-end;

      .formQuestion {
        margin-right: $spacer * 2;
        margin-bottom: $spacer;
        padding-bottom: 0;
        flex: auto;
      }

      .formQuestion.firstName,
      .formQuestion.lastName {
        width: calc((100% - #{$spacer * 2}) / 2);
      }

      .formQuestion.lastName {
        margin-right: 0;
      }
    }

    button {
      margin-bottom: $spacer;
    }

    @include max-width(sm) {
      .featureForm > div:first-child {
        .formQuestion {
          width: 100%;
          margin-right: 0;

          &.firstName,
          &.lastName {
            width: 100%;
          }
        }
      }

      button {
        margin: 0 auto;
      }
    }
  }
}
