@mixin alt-style(
  $base-colour,
  $off-base-colour-1,
  $off-base-colour-2,
  $top-colour
) {

  body {
    background-color: $base-colour;
    color: $top-colour;


    .pageHeader, #bodyForm, .pageFooterWrapper {
      *:before, *:after {
        display: none;
      }
    }
  }

  img,
  .mainLogo {
    filter: grayscale(100%);
  }

  h1, h2, h3, h4, h5, h6, p, span {
    color: $top-colour;
  }

  a {
    color: $top-colour !important;
    text-decoration: underline !important;
    // Todo: hover colour here?
  }

  .alert,
  .validateError {
    color: $top-colour;
  }

  a.buttonIcon {
    background-color: $top-colour;
    color: $base-colour;
  }

  .cta-button,
  .button,
  button,
  .readMore,
  .addToCalender {
    background-color: $top-colour !important;
    color: $base-colour !important;
    text-decoration: none !important;
    // Todo: hover colour here?
  }

  .cta-button,
  .button,
  button {
    &.donate {
      background-color: $top-colour;
      color: $base-colour;
    }
  }

  .uploadifive-button {
    background-color: $top-colour;
    color: $base-colour;
  }

  blockquote {
    background-color: $off-base-colour-1;
    color: $top-colour;
  }

  .commentDonationAmount {
    color: $top-colour;
  }

  .accordianHeader {
    background-color: $off-base-colour-1;
    color: $top-colour;
  }

  .donationGiftAid {
    background-color: $off-base-colour-1;
  }

  .donationGiftAidAmount {
    color: $top-colour;
  }

  @if $share-enabled == true {
    .shareWrapper {
      > strong {
        color: $top-colour;
      }
    }
  }

  .socialIcons,
  .shareList {
    a {
      background-color: $top-colour !important;

      &:before {
        display: flex !important;
        color: $base-colour !important;
      }
    }
  }

  .tab {
    background-color: $off-base-colour-1;

    &.active {
      background-color: $top-colour;
      a {
        color: $base-colour;
      }
    }
  }

  .videoPopupClose {
    background-color: $top-colour;
    &:before {
      color: $base-colour;
    }
  }

  .coverOurCostsContainer {
    background-color: $off-base-colour-1;
  }

  .coverCostLabel {
    color: $top-colour;
  }

  .formQuestion.donationAmount:not(.ccAmount) {
    &.donationSelected {
      background-color: $top-colour;
      border-color: $top-colour;
      color: $base-colour;

      .donationAmountFigure {
        color: inherit;
      }
    }

    .donationAmountFigure {
      color: $top-colour;
    }
  }

  .formQuestion.donationAmount.donationAmountOther {
    background-color: transparent;
  }

  @if $donors-list-enabled == true {
    .donorsList {
      background-color: $off-base-colour-1;
      color: $top-colour;

      > h2,
      > span {
        color: $top-colour;
      }
    }
  }

  .quickGivingPanel {
    background-color: $off-base-colour-1;
    color: $top-colour;

    .formQuestion.donationAmount {
      background-color: transparent;
      border: 2px solid $top-colour;
    }
  }

  @if $totaliser-enabled == true {
    .raisedWrapper, .totaliserFigure {
      color: $top-colour;
    }

    .totaliserBG {
      background-color: $off-base-colour-1;
    }

    .totaliserDone {
      background-color: $top-colour;
    }
  }

  @if $carousel-buttons-enabled == true {
    @if $carousel-buttons-type == dots {
      .carouselButtons a {
        background-color: $top-colour;
        opacity: .5;
        &:hover {
          opacity: 1;
        }
      }

      .carouselButtons .activeSlide a {
        background-color: $top-colour;
        opacity: 1;
      }
    }

    @if $carousel-buttons-type == tabs {
      .carouselButtons li {
        background-color: $off-base-colour-1;
        .button {
          background-color: transparent;
          color: $top-colour;
        }
      }

      .carouselButtons .activeSlide a {
        background-color: $top-colour;
        color: $base-colour;
      }
    }
  }

  @if $carousel-controls-enabled == true {
    .carouselControls a {
      background-color: $top-colour;
      color: $base-colour;
    }
  }

  .carouselSlideDetail {
    background-color: $off-base-colour-1;
  }

  .carouselSlideSummary {
    color: $top-colour;
  }

  .carouselSlideTitle.showsVideo {
    background-color: $base-colour;

    &:after {
      background-color: rgba($top-colour, .75);
      color: $base-colour;
    }
  }

  #{$newsletter} {
    background-color: transparent;
    color: $top-colour;

    > h1 {
      color: $top-colour;
    }

    button {
      background-color: $top-colour;
      color: $base-colour;
    }
  }

  .pageFooterWrapper {
    @if $footer-background-colour != transparent {
      background-color: $off-base-colour-1;
    }
  }

  .pageFooter {
    color: $top-colour;
  }

  @if $fundraising-enabled == true and $find-a-fundraiser-widget-enabled == true {
    .findAFundraiserWidget .searchFundraisers {
      background-color: $off-base-colour-1;
    }
  }

  @if $fundraising-enabled == true or $top-fundraisers-enabled == true {
    .topFundraisersWrapper {
      background-color: $off-base-colour-1;

      .fundraiserDetails p span {
        color: $top-colour;
      }
    }
  }

  @if $breadcrumb-enabled == true {
    .breadcrumbItem:before {
      color: rgba($top-colour, .5);
    }
  }

  @if $header-search-enabled == true {
    .header-search {
      button {
        background-color: $top-colour;
        &:before {
          color: $base-colour;
        }
      }
    }
  }

  @if $header-background-colour != transparent {
    .pageHeader {
      background-color: $base-colour;
    }
  }

  .mainCallToAction {
    .cta-button {
      background-color: $top-colour;
      color: $base-colour;
      &:hover {
        background-color: $top-colour;
        color: $base-colour;
      }
    }
  }

  @if $menu-admin-enabled == true {
    .menuAdminContainer {
      background-color: $off-base-colour-1;
    }

    #menuAdmin a {
      color: $top-colour;
    }
  }

  @if $tagline-enabled == true {
    .tagline {
      color: $top-colour;
    }
  }

  .homeFeaturesWrapper {
    background-color: transparent;
  }

  @if $home-feed-twitter-enabled == true {
    .homeFeedTwitter .tweetsWrapper {
      li {
        background-color: transparent;
        color: $top-colour;
      }
    }
  }

  .homeFeedsWrapper {
    background-color: transparent;
  }

  .homeFeed {
    background-color: transparent;

    .feedsTitle {
      color: $top-colour;
    }
  }

  @if $impact-stats-enabled == true {
    .homefeaturecategory-homeboximpactstats {
      background-color: $off-base-colour-1;
      color: $top-colour;
    }

    .homeImpactWrapper {
      td:first-child {
        h2, h3, h4 {
          color: $top-colour;
        }
      }

      td:last-child {
        color: $top-colour;
      }
    }
  }

  .homeIntroWrapper {
    background-color: $off-base-colour-1;
    color: $top-colour;
  }

  @if $listed-appeal-totaliser-enabled == true {
    .listedPost .appealFooterWrapper {
      .totaliserBG {
        background-color: $off-base-colour-1;
      }
    }
  }

  .menuMainAlt {
    background-color: $top-colour;
    color: $base-colour;
  }

  .menuMain .topLevel {
    a {
      color: $top-colour !important;
      background-color: transparent !important;
      &:hover {
        background-color: rgba($top-colour, .1) !important;
      }
    }
  }

  .subMenu {
    background-color: $off-base-colour-2;
  }

  @if $nav-top-level-chevrons-enabled == true {
    li.level1.hasSubmenu > a:after {
      color: $top-colour;
      margin-left: 10px;
    }
  }

  @if $nav-type == normal {
    @if $nav-background-colour != transparent {
      .menuMain {
        background-color: $off-base-colour-1;
      }
    }

    ul.subMenu .hasSubmenu > a:after {
      color: $top-colour !important;
    }
  }

  .searchListingIntroWrapper .searchContainer {
    background-color: $off-base-colour-1;
    color: $top-colour;
  }

  @if $shop-enabled == true {
    .productPrice {
      color: $top-colour;
    }
  }

  .post .contentBlock {
    .postAside > * {
      @if $sidebar-background-colour != transparent {
        background-color: $off-base-colour-1;
      }
    }
  }

  @if $subsite-enabled == true {
    .menuSub {
      @if $subsite-nav-background-colour != transparent {
        background-color: $off-base-colour-2;
      }
    }
  }

  // Cards
  // Need to !important these as they can be targetted more specifically
  // e.g. homeFeedBox1 .feedItem
  .homeFeatures .homeFeature,
  .feedItem,
  .listedPost {
    border-color: $base-colour !important;

    #{$card-image-link} {
      background-color: $base-colour !important;
    }

    #{$card-details} {
      background-color: $base-colour !important;

      > p {
        color: $top-colour !important;
      }
    }

    h2,
    .feedTitle {
      a {
        background-color: $base-colour !important;
        color: $top-colour !important;
      }
    }

    &:hover {
      border-color: $base-colour !important;

      #{$card-details} {
        background-color: $base-colour !important;

        > p {
          color: $top-colour !important;
        }
      }

      h2, .feedTitle {
        a {
          color: $top-colour !important;
          text-decoration: underline;
        }
      }
    }
  }

  // Todo: sticky header
}