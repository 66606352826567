.pageFooterWrapper {
  width: 100%;
  max-width: 100%;
  margin: $footer-margin-y auto 0;
  text-align: $footer-text-align;

  // Solid colour
  @if $footer-background-gradient == none {
    background-color: $footer-background-colour;
  }

  // Gradient
  @else {
    background: $footer-background-gradient;
  }
}

.pageFooter {
  width: 100%;
  margin: 0 auto;
  color: $footer-colour;
  padding: $footer-padding-y 0;
  display: grid;
  font-size: $footer-font-size;

  $footer-main-col-bleed: $site-bleed - ($card-gap-width / 2);

  @if ($footer-contents-max-width == 100%) {
    grid-template-columns: 1fr 0px repeat(12, (100% / 12)) 0px 1fr;
  } @else {
    $footer-main-col: $footer-contents-max-width - ($footer-main-col-bleed * 2);
    grid-template-columns: 1fr $footer-main-col-bleed repeat(
        12,
        (#{$footer-main-col / 12})
      ) $footer-main-col-bleed 1fr;

    @media (max-width: #{$footer-contents-max-width + ($site-bleed * 2)}) {
      grid-template-columns: 0px $footer-main-col-bleed repeat(12, 1fr) $footer-main-col-bleed 0px;
    }
  }

  ul {
    @include list-unstyled;
    // display: flex; // Don't assume this
    flex-wrap: wrap;

    @if $footer-text-align == center {
      justify-content: center;
    }

    @if $footer-text-align == right {
      justify-content: flex-end;
    }
  }

  a:not(.button) {
    color: $footer-link-colour;
    text-decoration: $footer-link-decoration;

    &:hover {
      color: $footer-link-hover-colour;
      text-decoration: $footer-link-hover-decoration;
    }
  }

  .socialIcons a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-size: $footer-headings-font-size;
    text-transform: $footer-headings-text-transform;
  }

  @if ($shop-enabled == false) or ($footer-admin-basket-link-enabled == false) {
    .menuAdminBasket {
      display: none;
    }
  }

  .menuAdminBasket:before {
    display: none;
  }

  @if $footer-admin-login-link-enabled == false {
    .menuAdminLogin {
      display: none;
    }
  }

  @if $footer-admin-manager-link-enabled == false {
    .menuAdminManager {
      display: none;
    }
  }

  @if $footer-admin-my-details-link-enabled == false {
    .menuAdminDetails {
      display: none;
    }
  }

  @if $footer-admin-logout-link-enabled == false {
    .menuAdminLogOut {
      display: none;
    }
  }
}

.footerBox {
  max-width: 100%;
}

// Nobody knows what this actually does...
.footerTwitter {
  display: none;
}

// Wrap email addresses
.footerBox a[href*="mailto:"] {
  word-wrap: break-word;
}