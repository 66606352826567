// Common styles between the header search and mobile search
@if ($header-search-enabled == true) or ($nav-search-enabled == true) {
  .header-search {
    // Hide the label - not sure we ever want this
    .siteSearch {
      display: none;
    }

    // Inline-block font-size hack
    .searchContainer {
      font-size: 0;
      flex-grow: 1;
    }

    .searchContainer input,
    button {
      display: inline-block;
      vertical-align: middle;
    }

    .searchContainer input {
      width: 100%;
      height: $header-search-input-height;
      font-size: 1rem;
      padding: $header-search-input-padding;
      border-radius: $header-search-input-border-radius;
      border: 0; // reset this context
      border-color: $header-search-input-border-colour;
      border-width: $header-search-input-border-width;
      border-style: solid;
      background-color: $header-search-input-background-colour;
      color: $header-search-input-colour;
      margin-right: $header-search-input-margin-right;

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::placeholder {
        color: $header-search-input-placeholder-colour;
      }

      &:focus {
        background-color: $header-search-input-focus-background-colour;
        color: $header-search-input-focus-colour;
      }
    }

    // Change the button to just an icon
    button {
      width: $header-search-button-width;
      height: $header-search-button-height;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $header-search-button-background-colour;
      margin-right: 0;
      padding: 0;
      border-radius: $header-search-button-border-radius;
      border: 0;
      font-size: 0;

      &:before {
        display: block;
        content: '\f002';
        font-size: $header-search-button-icon-font-size;
        font-family: $icon-font-family;
        color: $header-search-button-icon-colour;
      }

      &:hover {
        @include button-hover-state($header-search-button-background-colour);
      }
    }
  }
}

@if ($warn == true) {
  @if ($header-search-enabled == false) or ($nav-search-enabled == false) {
    @warn '#{$platform-name}: Search bar(s) have been disabled - please consider retaining the "/search" link in the footer for accessibility reasons';
  }
}

//
// Search in header
//

.headerContent .header-search {
  display: none; // Hide this by default
}

@if $header-search-enabled == false {
  .headerContent .mainCallToAction {
    flex-grow: 1;
  }
}

@if $header-search-enabled == true {
  @media (min-width: $header-search-breakpoint + 1px) {
    .headerContent .header-search {
      display: flex;
      margin-right: $header-search-margin-right;

      .searchContainer {
        text-align: right; // Send items to the right
      }

      .searchContainer input {
        max-width: $header-search-input-max-width;
      }
    }
  }

  @media (max-width: $header-search-breakpoint) {
    .headerContent .mainCallToAction {
      flex-grow: 1;
    }
  }
}

//
// Search in navigation
//

.menuMain .header-search {
  display: none; // Hide this by default
}

@if $nav-search-enabled == true {
  @media (max-width: $header-search-breakpoint) {
    .menuMain .header-search {
      display: flex;
      margin: 0;
      padding: $nav-submenu-item-padding;

      .searchContainer input {
        margin: 0 $nav-search-input-margin-right 0 0;
        max-width: calc(100% - #{$header-search-button-width} - #{$nav-search-input-margin-right});
        border-width: $nav-search-input-border-width;
        @include if-required(
          padding,
          $header-search-input-padding,
          $nav-search-input-padding
        );
        @include if-required(
          border-color,
          $header-search-input-border-colour,
          $nav-search-input-border-colour
        );
        @include if-required(
          background-color,
          $header-search-input-background-colour,
          $nav-search-input-background-colour
        );

        &::placeholder {
          @include if-required(
            color,
            $header-search-input-placeholder-colour,
            $nav-search-input-placeholder-colour
          );
        }
      }

      button {
        margin: 0;
        @include if-required(
          background-color,
          $header-search-button-background-colour,
          $nav-search-button-background-colour
        );

        &:before {
          @include if-required(
            color,
            $header-search-button-icon-colour,
            $nav-search-button-icon-colour
          );
        }
      }
    }
  }
}
