.tabbedListing:not(.fundraisersWrapper) .categoryFilterWrapper {
  max-width: $listing-content-max-width;
  margin: 0 auto;
  padding-left: $site-bleed;
  padding-right: $site-bleed;
}

.categoryFilterContent {
  @include context-container();
  display: flex;
  flex-wrap: wrap;

  > h2 {
    order: 1;
    font-size: $font-size-h5;
    width: 100%;
  }
}

.listedFilters {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  order: 2;

  li {
    @include button();
    margin-bottom: 5px;

    a, a:hover {
      text-decoration: none;
    }
  }
}

// Specifically when using 'single'
.singleCategoryFilter .categoryFilterContent {
  // Slightly trim the padding to compensate for each li's margin
  padding-bottom: calc(#{$context-container-padding} - 5px);
  padding-right: calc(#{$context-container-padding} - 5px);
}

.secondLevelFilters {
  display: none; // TODO
}

// These contain checkbox and label pairs when on 'multiple'
// AND label and selects when 'combined select'
.groupedFilters {
  order: 3;
  margin: 5px $spacer 5px 0;

  h3 {
    font-size: $font-size-h6;
    margin: 0 0 5px 0;
    font-weight: $font-weight-base;
    font-family: $font-family-base;
  }

  select {
    width: 100%;
  }

  .checkboxRadio {
    margin: 0;
  }
}

// Specifically when we are using 'combined select'
.combinedCategoryFilter {
  .categoryFilterContent {
    // Reduce the right padding here to hack neat dropdown columns
    // whilst not having to nth-child calc margin reset on .groupedFilters
    padding-right: calc(#{$context-container-padding} - #{$spacer});
  }

  .groupedFilters {
    flex: 1;
    min-width: 240px;
    margin-bottom: $spacer;
  }
}

// When using 'multiple', this contains all of the filters that have been applied
.appliedFilters {
  width: 100%;
  order: 4;
  font-size: $font-size-small;
  padding: 1em;
  background-color: rgba($black, .05);
  margin: 0;

  h3 {
    font-size: $font-size-h6;
    margin: 0;
  }

  ul {
    @include list-unstyled;
  }

  li {
    list-style: none;
    margin: 5px 5px 0 0;
  }

  li {
    @include button();

    &:hover {
      background-color: $btn-background-colour;
      cursor: default;
    }

    a, a:hover {
      text-decoration: none;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .remove {
    @include button-icon (
      $btn-background-colour: rgba(#000, .25)
    );
    margin: 0 0 0 8px;

    &:hover {
      cursor: pointer;
    }
  }
}

.clearcombinedfilters {
  order: 4;
  display: block;
  width: 100%;
  font-size: $font-size-small;
  text-align: right;
  margin-right: $spacer;
}

// This doesn't actually work when we have 'multiple' filter type enabled
// But a little 'x' button gets generated anyway. So let's hide it.
.multipleCategoryFilter .appliedFilters .remove {
  display: none;
}


//
// Responsive
//

@include max-width(sm) {
  .listedFilters li {
    flex-grow: 1;
  }

  .singleCategoryFilter .categoryFilterContent {
    padding-bottom: calc(#{$context-container-padding / 2} - 5px);
    padding-right: calc(#{$context-container-padding / 2} - 5px);
  }

  .groupedFilters {
    flex-grow: 1;
  }

  .combinedCategoryFilter {
    .categoryFilterContent {
      padding-right: calc(#{$context-container-padding / 2} - #{$spacer});
    }

    .groupedFilters {
      flex: auto;
      width: 100%;
      min-width: auto;
    }
  }
}