@mixin heading-underline (
  $colour: $brand-primary,
  $width: 2em,
  $height: .2em,
  $justify: left,
  $margin-top: $headings-margin-bottom,
  $margin-bottom: $headings-margin-bottom
) {
  &:after {
    content: '';
    display: block;
    width: $width;
    height: $height;
    background-color: $colour;
    margin-top: $margin-top;
    margin-bottom: $margin-bottom;

    @if $justify == center {
      margin-left: auto;
      margin-right: auto;
    }

    @if $justify == right {
      margin-right: 0;
      margin-left: auto;
    }
  }
}