@if $header-sticky-enabled == true {
  .pageHeader.sticky {
    background-color: $header-sticky-background-colour;
    box-shadow: $header-sticky-box-shadow;

    .headerContent {
      margin-top: $header-sticky-header-content-margin-top;
      margin-bottom: $header-sticky-header-content-margin-bottom;
    }

    .mainLogo {
      background-image: url($assets-path + $header-sticky-logo);
      width: $header-sticky-logo-width;
      height: $header-sticky-logo-height;

      @media
      only screen and (        min-device-pixel-ratio: 2),
      only screen and (                min-resolution: 192dpi),
      only screen and (                min-resolution: 2dppx) {
        background-image: url($assets-path + $header-sticky-logo-retina);
      }
    }

    @if $header-sticky-menu-admin-enabled == false {
      .menuAdminContainer {
        display: none;
      }
    }

    @if $header-sticky-search-enabled == false {
      .headerContent {
        .header-search {
          display: none;
        }

        .mainCallToAction {
          flex-grow: 1;
        }
      }
    }

    @if $header-sticky-social-icons-enabled == false {
      .headerContent .socialIcons {
        display: none;
      }
    }

    @if $header-sticky-cta-btns-enabled == false {
      .headerContent .mainCallToActionButtons {
        display: none;
      }
    }

    .menuMain {
      margin-bottom: $header-sticky-nav-margin-bottom;
    }
  }
}