html {
  box-sizing: border-box;
  scroll-behavior: smooth; 

  // Changes the default tap highlight to be completely transparent in iOS.
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-colour;
  background-color: $body-bg;
}


//
// Media
//

img:not(.mediaImage),
video {
  height: auto;
  width: auto;
}

img, video, #map {
  max-width: 100%;
}

// Apart from when in a table cell, which causes a column width bug
td img {
  max-width: initial;
}


//
// Typography
//

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}


//
// Lists
//

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}


//
// Tables
//

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

// The TinyMCE editor allows users to very easily set height and width values here,
// which more often than not break things on the front end. I'm resetting height
// values here to try and negate a lot of that fragility
table, tbody, th, tr, td {
  height: auto !important;
}


//
// Forms
//

label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
  margin-bottom: .5rem;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  // Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc are
  // properly inherited. However, `line-height` isn't inherited there.
  line-height: inherit;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  // Remove the default appearance of temporal inputs to avoid a Mobile Safari
  // bug where setting a custom line-height prevents text from being vertically
  // centered within the input.
  // See https://bugs.webkit.org/show_bug.cgi?id=139848
  // and https://github.com/twbs/bootstrap/issues/11266
  -webkit-appearance: listbox;
}

textarea {
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  resize: vertical;
}

fieldset {
  // Browsers set a default `min-width: min-content;` on fieldsets,
  // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  // So we reset that to ensure fieldsets behave more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359
  // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  min-width: 0;
  // Reset the default outline behavior of fieldsets so they don't affect page layout.
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  // Reset the entire legend element to match the `fieldset`
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
}


//
// Misc utility classes from the platform
//

.hidden {
  display: none !important;
}


@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important; // scss-lint:disable ImportantRule
    transition: none !important; // scss-lint:disable ImportantRule
  }
}
