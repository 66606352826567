@mixin card-side-by-side(
  $card-side-by-side-image-position: $card-side-by-side-image-position,
  $card-side-by-side-image-width: $card-side-by-side-image-width,
  $card-side-by-side-grow-image: $card-side-by-side-grow-image,
  $card-side-by-side-vertical-text-position:
    $card-side-by-side-vertical-text-position,
  $card-side-by-side-breakpoint: $card-side-by-side-breakpoint,
  $prefab: 0
) {
  display: grid;
  grid-template-rows: auto 1fr auto;

  @if $card-side-by-side-image-position == left {
    grid-template-columns: $card-side-by-side-image-width 1fr;
  } @else {
    grid-template-columns: 1fr $card-side-by-side-image-width;
  }

  #{$card-image-link} {
    display: block;
    width: 100%;
    height: 100%;

    @if $card-side-by-side-grow-image == true {
      grid-row: 1 / 4;
    } @else {
      grid-row: 1 / 2;
    }

    @if $card-side-by-side-image-position == left {
      grid-column: 1 / 2;
    } @else {
      grid-column: 2 / 3;
    }
  }

  @if $card-side-by-side-grow-image == false {
    .banner {
      object-fit: initial;
      height: auto;
    }
  }

  // Fix for object-fit cover in IE using object-fit-images
  .banner {
    font-family: 'object-fit: cover; object-position: center;';
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
    }
  }

  #{$card-details} {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    width: 100%;

    @if $card-side-by-side-vertical-text-position == center {
      justify-content: center;
    }

    @if $card-side-by-side-vertical-text-position == bottom {
      justify-content: flex-end;
    }
  }

  footer {
    width: 100%;
    grid-row: 3 / 4;
    @if $card-side-by-side-image-position == left {
      grid-column: 2 / 3;
    } @else {
      grid-column: 1 / 2;
    }
  }

  // Where we DO have an image or video, just need to span the details
  a + [class*='DetailsWrapper']:not(.publishDetailsWrapper),
  a + [class^='listed'][class$='Text'],
  .embed-container + [class*='DetailsWrapper']:not(.publishDetailsWrapper),
  .embed-container + [class^='listed'][class$='Text'] {
    @if $card-side-by-side-image-position == left {
      grid-column: 2 / 3;
    } @else {
      grid-column: 1 / 2;
    }
  }

  //
  // Prefab styles
  //
  @if $prefab == 1 {
    // Default
  }

  @if $prefab == 2 {
    @include min-width(sm) {
      align-items: center;
      padding-top: $spacer; // Create a gap at the top and bottom of the overall element
      padding-bottom: $spacer;

      @if $card-side-by-side-grow-image == true {
        #{$card-image-link} {
          height: calc(100% + #{$spacer * 2}); // Then 'grow' the image to ignore the parent padding.
          // I previously had set a height style on the details, but designers had started applying this prefab
          // to event feed items, where the client wanted the date to appear. So this had to be refactored
          // onto the parent element.
        }
      }

      // If we have an image only
      a + [class*='DetailsWrapper']:not(.publishDetailsWrapper),
      a + [class^='listed'][class$='Text'] {
        height: 100%;
        transition: transform $transition-duration $transition-curve;
        grid-row: 1 / 3;

        @if $card-side-by-side-image-position == left {
          transform: translateX(#{$spacer * -1});
        } @else {
          transform: translateX(#{$spacer});
        }
      }

      footer {
        background-color: $grey-lightest; // Force this here
        transition: transform $transition-duration $transition-curve;
        grid-row: 3 / 4;

        .postFooter > * {
          opacity: 1; // We need this full opacity
        }

        @if $card-side-by-side-image-position == left {
          transform: translateX(#{$spacer * -1});
        } @else {
          transform: translateX(#{$spacer});
        }
      }

      &:hover {
        a + [class*='DetailsWrapper']:not(.publishDetailsWrapper),
        a + [class^='listed'][class$='Text'],
        footer {
          @if $card-side-by-side-image-position == left {
            transform: translateX(#{$spacer * 2 * -1});
          } @else {
            transform: translateX(#{$spacer * 2});
          }
        }
      }
    }
  }

  @include prefab-warn($prefab, 2);

  //
  // Responsive
  //

  @media (max-width: #{$card-side-by-side-breakpoint}) {
    display: flex;
    flex-wrap: wrap;

    #{$card-image-link} {
      height: auto;
    }

    #{$card-image-link},
    #{$card-details} {
      width: 100%;
    }
  }
}
