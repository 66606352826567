// Used for uploading images to comments, posts etc.

#uploadImage {
  max-width: 100%;
  margin-bottom: $spacer;
  #preview-container {
    max-width: 100%;
  }
}

.formQuestion.addMedia {
  padding-bottom: 0;

  label {
    margin-bottom: 0;
  }
}

@media (max-width: 540px) {
  .commentImageWrapper {
    width: 100%;
  }
}