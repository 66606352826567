// Comments list at bottom of a page, for example
// When it's at the bottom of an appeal, it is within tabs
// and then we don't want the border or the top padding
.postContent > .postComments {
  padding-top: $spacer * 2;
  border-top: $border-width solid $border-colour;
}

#makeComment,
.appealInteractions .tabbedListingTitle {
  @extend h5;
}

.commentForm {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $gap-width;

    .addComment {
      order: 9;
    }
  }

  .enterComment {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer;

    textarea {
      min-height: 160px;
    }
  }

  .registerCommentsWrapper {
    width: 100%;
  }

  .commentImageWrapper {
    padding-right: 0 !important; // Override dropzoneFileUploaderComments.css
    max-width: 100%;
  }

  @if $comment-add-image-enabled == false {
    .commentImageWrapper {
      display: none;
    }
  }

  @if $comment-add-video-enabled == false {
    .commentVideoWrapper {
      display: none;
    }
  } @else {
    .commentVideoWrapper {
      .addVideo {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .commentNotify {
    @include context-container;
    display: flex;
    flex-direction: row-reverse; // Put checkbox first
    align-items: center;
    padding: $spacer;

    label {
      flex-grow: 1;
      margin: 0 0 0 .5rem;
    }
  }
}


.commentsList {
  @include list-unstyled;
  list-style: none;

  li {
    padding: $spacer 0;
    border-bottom: 1px solid $border-colour;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;

    &:first-child {
      border-top: 1px solid $border-colour;
    }
  }
}

img.commentProfilePic {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  width: 60px;
  margin-right: $spacer;
}

.commentDonationAmount {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  color: $donate-colour;
}

.commentContentWrapper {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.commentDetails {
  font-size: $font-size-small;

  p {
    margin: 0;
  }
}

.postCommentsAlt {
  margin-top: $gap-width;
}