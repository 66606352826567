//
// LISTING
//

// Hide header Donate on InMem pages
body.inMemType .mainCallToAction .cta-button.donate {
  display: none;
}

// Fix some inconsistent markup which breaks layout
.inMemoryListingPage .contentBlock .listContent {
  margin-left: 0;
  margin-right: 0;
  display: block; // Helps with layout of #memorialArea

  > h2 {
    display: none;
  }
}

// Breadcrumb just has single item of 'inMemoryOf'
// which looks stupid and doesn't do anything
.inMemoryListingPage .breadcrumbWrapper {
  display: none;
}

// "Looking for an existing memorial" block
.findAMemorial {
  @include context-container;
  width: 100%;
  max-width: 768px;
  display: flex;
}

.findAMemorialForm {
  flex: 1;
  legend { display: none; }
}

.formQuestion.findAMemorialName {
  padding-bottom: 0;
  height: 100%;
  label { display: none;}
  input  {
    height: 100%;
    border-radius: $context-container-border-radius 0 0 $context-container-border-radius;
  }
}

button.findAMemorialBtn {
  margin-right: 0;
  @include max-width(md) {
    font-size: 0;
    width: $header-search-button-width;
    height: $header-search-button-height;
    padding: 0;
    border-radius: 0 $context-container-border-radius $context-container-border-radius 0;
    border: 0;

    &:before {
      display: block;
      content: '\f002';
      font-size: $header-search-button-icon-font-size;
      font-family: $icon-font-family;
      color: text-contrast($btn-background-colour);
    }
  }
}


// Container for listed posts
#memorialArea {
  display: flex;
  flex-wrap: wrap;
  margin-left: ($card-gap-width / 2) * -1;
  margin-right: ($card-gap-width / 2) * -1;

  // "No memorial can be found with that name"
  > p {
    margin:  0 ($card-gap-width / 2);
    color: $danger-colour--on-white;
  }
}

// Card
.listedInMemory {
  .life {
    display: block;
    margin: $spacer 0 0;
    font-weight: $headings-font-weight;
  }
}

.inMemoryListingPage {
  // Turn the wrapper into a flexbox so we can hoist up the 'create' button
  // Which I think works better up the top of the page...
  .contentBlockWrapper {
    display: flex;
    flex-direction: column;
  }

  // Reveal sidebar as this is where the 'create' button lives and hoist it
  .postAside {
    display: block !important;
    order: -1;
    margin: 0 0 $gap-width*1.5 0;
    text-align: center;
  }
}

// The button itself
.createInMemBtn {
  font-size: $font-size-large;
  margin-right: 0;
}

//
// CREATE PAGE
//

// See _post-create-form.scss for more related to this

.inMemoryCreate {
  // Hide breadcrumb, just has a single 'inmemoryof' item
  .breadcrumbWrapper {
    display: none;
  }
}

.formWrapper {
  fieldset.inMemoryCreateContinue fieldset.inMemoryCreateButtons {
    border: none;
  }
}

// Checkbox for associated appeal options
.inMemoryAssociatedAppeal .formQuestion.labelCheckBoxPair {
  width: 100%;
  justify-content: flex-end;
}

//
// THANKYOU PAGE AFTER CREATION
//

.inMemoryCreateSuccess {
  width: 100%;
  max-width: $post-content-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $site-bleed;
  padding-right: $site-bleed;

  h2 {
    font-size: $font-size-h4;
  }

  > h2 {
    text-align: center;
  }

  // "View your page" button
  > .button {
    display: block;
    font-size: $font-size-large;
    margin: 15px auto;
  }
}

.startFundraising {
  @include context-container;
  margin-top: $gap-width;
}


//
// IN MEMORY APPEAL PAGE
//

// We get a random empty .headerText div on this page,
// which adds some unwanted vertical padding and margin
.inMemPostPage .headerText {
  display: none;
}

.postContent .inMemIntro {
  margin-top: $gap-width;
  font-size: $font-size-h4;
  opacity: .5;
}

// Banner image
// The platform doesn't crop this to the banner dimensions from settings
// because this risks poorly cropping a photo of a deceased loved one
// Therefore there's a high chance that this image will be quite a square
// aspect ratio, and we should max out the size it can be displayed at

.inMemPostPage .carouselSlide img {
  width: auto;
  max-width: 600px;
  max-height: 400px;
  margin: $gap-width auto 0;
}

.inMemContent {
  text-align: center;
  max-width: 900px; // Disregard $post-content-max-width here. Let's keep this neat.
}

// Date of birth
.inMemLife {
  font-size: $font-size-h4;
  opacity: .75;
}

// Donte and fundraise buttons
.inMemContent .appealActions {
  @include context-container;
  padding: 10px;

  .button {
    margin: 2.5px;
    font-size: $font-size-large;
  }
}

// This is the related appeal that stuff goes to
.inAidOf {
  border-top: 1px solid $border-colour;
  border-bottom: 1px solid $border-colour;
  padding: $gap-width 0;
  margin: $gap-width 0;
  font-size: $font-size-small;

  img {
    max-height: 150px;
  }

  .proceeds,
  .soFar {
    opacity: .75;
    margin: 0;
  }

  .amountRaised {
    font-size: 2rem;
    font-weight: $headings-font-weight;
    color: $donate-colour;
  }
}

// Reset comments to text-align-left
.inMemContent .appealInteractionsWrapper {
  text-align: left;
}


@if $listing-inmem-masonry-layout == true {

  .inMemoryListingPage {

    #memorialArea {
      display: block;
      column-gap: 0;
       @include min-width(md) { column-count: 2; }
       @include min-width(lg) { column-count: 3; }
    }

    // Individual post
    .listedPost.listedInMemory:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
      // display: block;
      width: auto;
      margin: #{$card-gap-width / 4} 0;
      overflow: visible;
      -webkit-column-break-inside: avoid;
      -moz-column-break-inside: avoid;
      break-inside: avoid;
      
      // Lazy load fix
      .listingBanner {
        min-height: 0.1px;
      }
    }

  }
}